.quali-text {
  font-size: 20px;
}

.qualifications-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  user-select: none;
}

.qualifications-container .qualification {
  height: 200px;
  min-width: 20%;
  background-color: rgb(9, 6, 31);
  border-radius: 20px;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.qualifications-container .qualification .content {
  height: 85%;
  width: 90%;
  border-radius: 15px;
  margin-top: 20px;
  background-size: contain;
  background-position: center;
}

.qualifications-container .qualification h1 {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.qualifications-container .qualification .content.angular {
  background: rgba(255, 255, 255, 0.103) url(../../Images/Angular.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}
.qualifications-container .qualification .content.react {
  background: rgba(255, 255, 255, 0.103) url(../../Images/react.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}
.qualifications-container .qualification .content.nest {
  background: rgba(255, 255, 255, 0.103) url(../../Images/NestJS.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}
.qualifications-container .qualification .content.express {
  background: rgba(255, 255, 255, 0.103) url(../../Images/express.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}
.qualifications-container .qualification .content.csharp {
  background: rgba(255, 255, 255, 0.103) url(../../Images/c-sharp.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}
.qualifications-container .qualification .content.ts {
  background: rgba(255, 255, 255, 0.103) url(../../Images/ts.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}
.qualifications-container .qualification .content.scss {
  background: rgba(255, 255, 255, 0.103) url(../../Images/scss.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}
.qualifications-container .qualification .content.html {
  background: rgba(255, 255, 255, 0.103) url(../../Images/html.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 820px) {
  .quali-text {
    font-size: 30px;
    top: 10px;
    text-align: center;
  }

  .qualifications-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 15px;
  }

  .qualifications-container .qualification {
    position: relative;
    height: 20%;
    min-width: 35%;
    background-color: rgb(9, 6, 31);
    border-radius: 20px;
    margin: 0 30px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .qualifications-container .qualification .content {
    height: 85%;
    width: 90%;
    border-radius: 15px;
    margin-top: 20px;
    background-size: contain;
    background-position: center;
  }

  .qualifications-container .qualification h1 {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 200;
  }
}

@media (max-width: 500px) {
  .quali-text {
    font-size: 20px;
    top: 10px;
    text-align: center;
  }

  .qualifications-container {
    position: absolute;
    display: flex;
    justify-content: center ;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 15px;
  }

  .qualifications-container .qualification {
    position: relative;
    height: 20%;
    min-width: 35%;
    background-color: rgb(9, 6, 31);
    border-radius: 20px;
    margin: 0 40px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .qualifications-container .qualification .content {
    height: 85%;
    width: 90%;
    border-radius: 15px;
    margin-top: 20px;
    background-size: contain;
    background-position: center;
  }

  .qualifications-container .qualification h1 {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 200;
  }
}

@media (max-width: 420px) {
  .quali-text {
    font-size: 20px;
    top: 10px;
    text-align: center;
  }

  .qualifications-container {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 40px;
  }

  .qualifications-container .qualification {
    position: relative;
    height: 20%;
    min-width: 40%;
    background-color: rgb(9, 6, 31);
    border-radius: 20px;
    margin: 0 40px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .qualifications-container .qualification .content {
    height: 85%;
    width: 90%;
    border-radius: 15px;
    margin-top: 20px;
    background-size: contain;
    background-position: center;
  }

  .qualifications-container .qualification h1 {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 200;
  }
}

@media (max-width: 400px) {
  .quali-text {
    font-size: 20px;
    top: 10px;
    position: absolute;
    left: 10px;
  }

  .qualifications-container {
    position: absolute;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .qualifications-container .qualification {
    position: relative;
    height: 20%;
    min-width: 40%;
    background-color: rgb(9, 6, 31);
    border-radius: 20px;
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
  }

  .qualifications-container .qualification .content {
    height: 85%;
    width: 90%;
    border-radius: 15px;
    margin-top: 20px;
    background-size: contain;
    background-position: center;
  }

  .qualifications-container .qualification h1 {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 200;
  }
}

@media (max-width: 375px) {
  .quali-text {
    font-size: 20px;
    top: 10px;
    position: absolute;
    left: 10px;
  }

  .qualifications-container {
    position: absolute;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .qualifications-container .qualification {
    position: relative;
    height: 20%;
    min-width: 40%;
    background-color: rgb(9, 6, 31);
    border-radius: 20px;
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .qualifications-container .qualification .content {
    height: 85%;
    width: 90%;
    border-radius: 15px;
    margin-top: 20px;
    background-size: contain;
    background-position: center;
  }

  .qualifications-container .qualification h1 {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 200;
  }
}
