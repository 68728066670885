.about-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 70vh;
  padding: 50px;
  overflow-x: hidden;
}

@media (max-width: 820px) {
  .about-container {
    position: relative;
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    min-height: 130vh;
    padding: 15px;
    overflow-x: hidden;
    backdrop-filter: blur(20px);
  }
}

@media (max-width: 500px) {
  .about-container {
    position: relative;
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    min-height: 135vh;
    padding: 20px;
    overflow-x: hidden;
    backdrop-filter: blur(20px);
  }
}

@media (max-width: 400px) {
  .about-container {
    position: relative;
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    min-height: 135vh;
    padding: 30px 10px 0;
    overflow-x: hidden;
    backdrop-filter: blur(20px);
  }
}
